import {Disclosure} from "@headlessui/react";
import {ChevronUpIcon} from "@heroicons/react/24/outline";

const qa = [
    {
        question: "Будут ли записи занятий?",
        answer: "Да, записи занятий будут публиковаться в приватном чате Telegram для всех участников курса."
    },
    {
        question: "Смогу ли задать вопрос вне занятий?",
        answer: "Все участники курса и преподаватель будут находиться в одном чате, поэтому задать вопрос можно в любое время."
    },
    {
        question: "Получу ли я сертификат о прохождении курса?",
        answer: "Нет, вы получите кое-что важнее - знания и навыки."
    },
    {
        question: "Смогу ли я вернуть деньги, если захочу отказаться от курса?",
        answer: "Да, пропорционально пройденным занятиям. Предположим, что Вы решили отказаться после второго занятия. " +
            "Сумма возврата = (стоимость курса / количество занятий) * (количество занятий курса - 2)."
    },
    {
        question: "Могу ли получить чек за услуги?",
        answer: "Да, мы соблюдаем законодательство и выдаем чеки после оплаты."
    },
    {
        question: "Как мне понять достаточно ли мне знаний для прохождения курса?",
        answer: "На странице есть описание требований к слушателям, на дополнительные вопросы всегда готовы ответить - просто оставьте заявку."
    },
    {
        question: "Сколько времени потребуется для прохождения курса?",
        answer: "Рекомендуется выделять не менее 10-20 часов в неделю для успешного прохождения."
    }
]

export default function FAQ() {
    return (
        <div className="w-full px-4 pt-16">
            <div className="mx-auto w-full rounded-2xl p-2 bg-gray-800">
                {qa.map(item => (
                    <Disclosure as="div" className="mt-2 p-2">
                        {({open}) => (
                            <>
                                <Disclosure.Button
                                    className="flex w-full justify-between rounded-lg bg-gray-700 px-4 py-2 text-left font-medium text-2xl text-white-900 hover:bg-gray-700 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75">
                                    <span>{item.question}</span>
                                    <ChevronUpIcon
                                        className={`${
                                            open ? 'rotate-180 transform' : ''
                                        } h-5 w-5 text-white-500`}
                                    />
                                </Disclosure.Button>
                                <Disclosure.Panel className="px-4 pb-2 pt-4 text-xl text-white-700">
                                    {item.answer}
                                </Disclosure.Panel>
                            </>
                        )}
                    </Disclosure>
                ))}
            </div>
        </div>
    )
}