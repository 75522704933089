import Navbar from "../../../components/Navbar";
import Footer from "../../../components/footer/Footer";
import Ivunin from "../../../components/Ivunin";
import {animated, useSpring} from "react-spring";
import {
    AcademicCapIcon,
    CalendarIcon,
    CheckBadgeIcon,
    CreditCardIcon,
    HomeIcon,
    PencilSquareIcon
} from '@heroicons/react/24/outline'
import FAQ from "../../../components/FAQ";
import MailForm from "./MailForm";
import Alert from "../../../components/Alert";

const themes = [
    "Сети и сетевые протоколы",
    "Системы сборок – Maven&Gradle",
    "Spring Core Technologies",
    "Spring Boot",
    "Взаимодействие с базами данных с помощью JDBC",
    "JPA, Hibernate",
    "Миграции баз данных на примере Liquibase",
    "Авторизация и аутентификация Spring Security",
    "OpenAPI как стандарт документирования API",
    "Контейнеризация Docker",
    "Spring Boot Test как неотъемлемая часть разработки. Testcontainers",
    "Работа с брокерами сообщений на примере Kafka",
    "Серия часто решаемых прикладных задач в ходе разработки"
];

const skills1 = [
    "Понимать работу с сетью веб-приложений",
    "Понимать как работает Spring",
    "Создавать Spring приложения с нуля",
    "Собирать jar файлы с помощью систем сборок",
    "Настраивать аутентификацию и авторизацию",
    "Использовать брокеры сообщений для асинхронного обмена",
    "Уметь решать типичные задачи в разработке веб-сервисов"
]

const skills2 = [
    "Упаковывать приложения в Docker-контейнеры",
    "Взаимодействовать с базой данных из приложения",
    "Применять правильный подход к изменению схемы данных в базе данных",
    "Обеспечивать предоставление контракта API",
    "Писать тесты с использованием контейнеров"
]

function Counter({end}) {
    const {number} = useSpring({
        from: {number: 0},
        number: end,
        delay: 300,
        config: {mass: 1, tension: 12, friction: 12}
    })
    return <animated.span>{number.to((end) => end.toFixed(0))}</animated.span>
}

export default function JavaWebCourse() {
    return (
        <div className="bg-gray-800">
            <Navbar/>

            <div
                className="grid sm:grid-cols-1 md:grid-cols-2 gap-4 mx-auto relative items-center p-10 w-full justify-center bg-gray-900 text-white">
                <div className="sm:p-2 md:p-10">
                    <h2 className="text-3xl md:text-4xl text-left font-semibold text-[#00abf1]">Веб-сервисы
                        на Java</h2>
                    <div className="pt-4">
                        <p className="sm:w-full text-xl">
                            Курс подойдет, если уже освоили основы языка Java, но не знаете куда или
                            как двигаться дальше. Упор на знания и навыки, востребованные на практике, делает курс
                            также полезным для тех, кто хочет закрепить уже изученное и узнать что-то новое (программа
                            постоянно
                            корректируется).
                        </p>
                    </div>
                </div>
                <div
                    className="sm:w-full mx-auto md:w-3/4 sm:p-1 md:p-6 h-full border border-gray-200 rounded-lg shadow dark:bg-gray-900 dark:border-gray-700">
                    <h5 className="p-4 mb-2 text-2xl font-bold tracking-tight text-[#00abf1] dark:text-white">
                        Курс в цифрах
                    </h5>
                    <div className="flex relative p-4 text-xl">
                        <div className="pt-1 pr-3">
                            <CheckBadgeIcon className="h-6 w-6"/>
                        </div>
                        <p>Закончили обучение - <Counter end={6}/></p>
                    </div>
                    <div className="flex relative justify-start p-4 text-xl">
                        <div className="pt-1 pr-3">
                            <AcademicCapIcon className="h-6 w-6"/>
                        </div>
                        <p>Занятий - 20</p>
                    </div>
                    <div className="flex relative justify-start p-4 text-xl">
                        <div className="pt-1 pr-3">
                            <CalendarIcon className="h-6 w-6"/>
                        </div>
                        <p>Продолжительность - 3 мес (планируемый старт - начало сентября)</p>
                    </div>
                    <div className="flex relative p-4 text-xl">
                        <div className="pt-1 pr-3">
                            <HomeIcon className="h-6 w-6"/>
                        </div>
                        <p>Домашних заданий - 15</p>
                    </div>
                    <div className="flex relative p-4 text-xl">
                        <div className="pt-1 pr-3">
                            <PencilSquareIcon className=" h-6 w-6"/>
                        </div>
                        <p>Тестовых заданий - 14</p>
                    </div>
                    <div className="flex relative p-4 text-xl">
                        <div className="pt-1 pr-3">
                            <CreditCardIcon className=" h-6 w-6"/>
                        </div>
                        <p>Цена - 40 000 &#x20bd;</p>
                    </div>
                </div>
            </div>

            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center text-white">
                <h2 className="text-3xl md:text-4xl text-center font-semibold text-[#00abf1]">Чему вы научитесь?</h2>
                <div className="pt-4">
                    <p className="sm:w-full text-xl">
                        После окончания курса вы сможете:
                    </p>
                    <div className="grid sm:grid-cols-1 md:grid-cols-3 pt-4">
                        <ul role="list" className="marker:text-sky-400 list-disc pl-5 space-y-3 text-xl">
                            {skills1.map(skill => (
                                <li className="flex space-x-2 rtl:space-x-reverse items-center">
                                    <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500"
                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    <span className="leading-tight">{skill}</span>
                                </li>
                            ))}
                        </ul>
                        <ul role="list" className="marker:text-sky-400 list-disc pl-5 space-y-3 text-xl">
                            {skills2.map(skill => (
                                <li className="flex space-x-2 rtl:space-x-reverse items-center">
                                    <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500"
                                         aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                         viewBox="0 0 20 20">
                                        <path
                                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                    </svg>
                                    <span className="leading-tight">{skill}</span>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>

            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center bg-gray-900 text-white">
                <h2 className="text-3xl md:text-4xl text-center font-semibold text-[#00abf1]">Как проходит курс</h2>
                <div className="pt-4">
                    <p className="sm:w-full text-xl">
                        Курс состоит из занятий, проводимых онлайн на базе Telegram в
                        вечернее время в будние дни (1 или 2 раза в неделю) и представляющих собой
                        теоретическое объяснение с практической демонстрацией. На каждом из занятий будет секция
                        вопросов и ответов.<br/><br/>
                        Для закрепления пройденного материала выдается домашнее задание, в ходе проверки которого
                        вы осознаете что такое "код-ревью". После каждого занятия запись и презентация буду доступны для
                        слушателей.
                        Перед началом занятий слушатели иногда проходят небольшое тестирование по предыдущей теме,
                        таким образом
                        выявляя недостатки знаний - все тесты проверяются вручную и в них редко встречаются варианты
                        ответов.
                        На всем протяжении курса у вас будет возможность задать вопрос по темам в чате и получить ответ
                        в адекватные сроки.
                        <br/><br/>
                        Занятие - 1,5 астрономических часа.
                        <br/><br/>
                        Всего занятий - 20 (30 часов).
                        <br/><br/>
                        Полная продолжительность - около 3 месяцев
                        (последние занятия будут проводиться раз в неделю для лучшей успеваемости)
                    </p>
                </div>
            </div>

            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center text-white">
                <h2 className="text-3xl md:text-4xl text-center font-semibold text-[#00abf1]">Программа курса</h2>
                <div className="pt-4">
                    <ul role="list" className="marker:text-sky-400 list-disc pl-5 space-y-3 text-xl">
                        {themes.map(theme => (
                            <li className="flex space-x-2 rtl:space-x-reverse items-center">
                                <svg className="flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500"
                                     aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                                     viewBox="0 0 20 20">
                                    <path
                                        d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                                </svg>
                                <span className="leading-tight">{theme}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center bg-gray-900 text-white">
                <h2 className="text-3xl md:text-4xl text-center font-semibold text-[#00abf1]">Преподаватель</h2>
                <Ivunin/>
            </div>

            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center text-white">
                <h2 className="text-3xl md:text-4xl text-center font-semibold text-[#00abf1]">Требования к
                    слушателям</h2>
                <div className="pt-4">
                    <p className="sm:w-full md:w-4/5 text-xl">
                        Курс не предназначен для изучения с нуля - просим учитывать это при подаче заявок.
                        Вам понадобится - знание основ языка Java, системы контроля версий Git, SQL и любой СУБД на
                        базовом уровне.
                    </p>
                </div>
            </div>

            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center bg-gray-900 text-white">
                <h2 className="text-3xl md:text-4xl text-center font-semibold text-[#00abf1]">А что после курса?</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 mx-auto md:w-1/2 gap-4 mt-8">
                    <div
                        className="mx-auto max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Мок-интервью
                        </h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-300">
                            После прохождения с каждым будет проведено 30-минутное тестовое собеседование, в ходе
                            которого
                            вы поймете слабоусвоенные темы.
                        </p>
                    </div>
                    <div
                        className="mx-auto max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                            Резюме
                        </h5>
                        <p className="mb-3 font-normal text-gray-700 dark:text-gray-300">
                            Последним шагом станет совместная подготовка или корректировка
                            резюме.
                        </p>
                    </div>
                </div>
            </div>

            <div className="mx-auto relative items-center mt-4 sm:p-1 md:p-10 w-full h-30 justify-center text-white">
                <h2 className="text-3xl md:text-4xl text-center font-semibold text-[#00abf1]">Частые вопросы</h2>
                <FAQ/>
            </div>

            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center bg-gray-900 text-white mb-10">
                <h2 className="text-3xl md:text-4xl text-center font-semibold text-[#00abf1]">Запись на курс</h2>
                <div className="pt-4">
                    <p className="w-full text-xl text-center">
                        Просто заполните заявку и мы с Вами свяжемся
                    </p>
                    <MailForm/>
                </div>
            </div>
            <Alert/>
            <Footer/>
        </div>
    )
}