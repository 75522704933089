const skills = [
    {name: 'Java'},
    {name: 'Networks'},
    {name: 'Spring - Boot, Security, Test'},
    {name: 'JDBC'},
    {name: 'JPA/Hibernate'},
    {name: 'PostgreSQL'},
    {name: 'Multithreading'},
    {name: 'Liquibase'},
    {name: 'Swagger'},
    {name: 'Docker'},
    {name: 'Maven'},
    {name: 'Gradle'},
    {name: 'Kafka'},
]
export default function Technologies() {
    return (
        <div className="mx-auto items-center p-1 h-50 bg-gray-900 text-white w-full">
            <div className="flex sm:flex-col md:flex-row mx-auto my-auto relative">
                <div
                    className="flex flex-wrap gap-2 pt-4 text-sm text-sky-600 *:rounded-full *:border *:border-sky-100 *:bg-sky-50 *:px-2 *:py-0.5 dark:text-sky-300 dark:*:border-sky-500/15 dark:*:bg-sky-500/10">
                    {skills.map((skill) => (
                        <div>{skill.name}</div>
                    ))}
                </div>
            </div>
        </div>
    )
}