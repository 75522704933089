export default function VideoRecommendations() {
    return (
        <div className="mx-auto relative items-center p-10 w-full justify-center bg-gray-800 text-white">
            <div className="flex items-center justify-center p-6">
                <h2 className="text-3xl md:text-4xl font-semibold text-[#00abf1]">Последнее популярное видео на
                    канале</h2>
            </div>
            <div className="w-full md:w-1/2 mx-auto">
                <div className="aspect-w-16 aspect-h-9 flex items-center justify-center">
                    <iframe src="https://www.youtube.com/embed/4tCV6-HxtyM"
                            title="HTTP методы - различия GET и POST на примере" frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
                </div>
            </div>
        </div>
    )
}