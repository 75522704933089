import Navbar from "../../components/Navbar";
import Banner from "../../components/Banner";
import Footer from "../../components/footer/Footer";
import Technologies from "./Technologies";
import {Link} from "react-router-dom";

export default function Courses() {
    return (
        <div className="bg-gray-800">
            <Navbar/>
            <Banner/>
            <div className="flex items-center justify-center p-6">
                <h2 className="text-4xl md:text-6xl font-semibold text-[#00abf1]">Доступные курсы</h2>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 m-8">
                <Link to="/courses/java-web">
                    <div
                        className="max-w p-6 border border-gray-200 rounded-lg shadow bg-gray-900 dark:border-gray-700">
                        <h5 className="mb-2 text-3xl md:text-4xl font-semibold text-white">
                            Веб-сервисы на Java
                        </h5>
                        <p className="mb-3 font-normal text-gray-400">
                            Изучаем полный комлект разработки веб-сервисов от самых популярных фреймворков до
                            контейнеризции и
                            асинхронного обмена сообщениями.<br/>
                            Продолжительность <span className="text-red-600">~3 месяца</span>
                        </p>
                        <Technologies/>
                        <p className="mb-3 font-normal text-gray-400">
                            Ближайший поток - <span className="text-red-600">сентябрь 2024</span>
                        </p>
                        <p className="mb-3 text-3xl font-bold text-white">
                           40 000 &#x20bd;
                        </p>
                        <button type="button"
                                className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-bold rounded-lg text-xl px-5 py-2.5 mt-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                            Подробнее
                        </button>
                    </div>
                </Link>
            </div>
            <Footer/>
        </div>
    )
}