import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Footer from "../components/footer/Footer";

export default function Blog() {
    return (
        <div className="bg-gray-800">
            <Navbar/>
            <Banner/>
            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center bg-gray-900 text-white">
                <h2 className="text-3xl md:text-4xl font-semibold text-[#00abf1]">Упс!</h2>
                <div className="pt-4">
                    <p className="sm:w-full md:w-4/5 text-xl">
                        В разработке. Будет совсем скоро (но это не точно). А пока подпишись на наши соцсети
                    </p>
                </div>
            </div>
            <Footer/>
        </div>
    )
}