import ivunin from "../assets/ivunin.jpg"

export default function Ivunin() {
    return (
        <div className="flex mx-auto justify-center items-center mt-3 mb-3 p-6 text-white sm:w-full md:w-2/3">
            <div className="grid justify-center gap-x-8 gap-y-12 grid-cols-1 sm:gap-y-16 xl:col-span-2">
                <div className="grid sm:grid-cols-1 md:grid-cols-2 justify-center items-center gap-x-6">
                    <div className="mx-auto">
                        <img className="h-52 h-52 rounded-full" src={ivunin} alt=""/>
                    </div>
                    <div className="grid grid-cols-1 sm:text-center md:text-left">
                        <span className="text-[#00abf1]">Автор курса</span>
                        <h3 className="text-2xl font-semibold text-white">
                            Виктор Ивунин
                        </h3>
                        <p className="font-semibold text-gray-300">
                            Senior Java разработчик в большой российской финтех-компании с опытом разработки более 10 лет.
                            Опыт преподавания - более 2 лет. Разработчик данной платформы.
                            Постоянно обучается сам и любит обучать других.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}