export default function Banner() {
    return (
        <div
            className="mx-auto items-center w-full h-96 md:h-screen bg-no-repeat justify-center text-white bg-[url('../src/assets/back-banner.jpg')]">
            <div className="flex relative h-full items-center justify-center bg-black/60">
                <div className="inline-flex flex-col items-center justify-center">
                    <div>
                        <h1 className="font-bold text-2xl md:text-5xl text-[#00abf1]">Java & Kotlin</h1>
                    </div>
                    <div>
                        <h2 className="font-bold text-2xl md:text-4xl">любим, практикуем,&nbsp;
                            <span className="text-red-600">обучаем</span>
                        </h2>
                    </div>
                </div>
            </div>
        </div>
    )
}