import {useRouteError} from "react-router";
import Navbar from "../components/Navbar";
import Footer from "../components/footer/Footer";
import Banner from "../components/Banner";

export default function ErrorPage() {
    const error = useRouteError();
    console.error(error);

    return (
        <div className="bg-gray-800">
            <Navbar/>
            <Banner/>
            <div className="mx-auto relative items-center p-10 w-full h-30 justify-center bg-gray-900 text-white">
                <h2 className="text-3xl md:text-4xl font-semibold text-[#00abf1]">Упс!</h2>
                <div className="pt-4">
                    <p className="sm:w-full md:w-4/5 text-xl">
                        Произошла какая-то ошибка. Возможно такой страницы уже нет или ее украл НЛО.
                        Если Вы уверены в правильности действий, просьба обратиться по адресу support@jvmbrain.ru.
                    </p>
                    <p>
                        <i>{error.statusText || error.status}</i>
                    </p>
                </div>
            </div>
            <Footer/>
        </div>

    );
}