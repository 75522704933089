import telegramIcon from "../../assets/icons/telegram-black.svg"
import youtubeIcon from "../../assets/icons/youtube-black.svg"
import logo from "../../assets/logo-black.png";
import {Link} from "react-router-dom";

const sections = [
    {
        title: "Курсы",
        links: [
            {name: "Веб-сервисы на Java", href: "/courses/java-web"}
        ],
    },
    {
        title: "Полезное",
        links: [
            {name: "Вакансии", href: "/vacancy"}
        ],
    },
    {
        title: "Документы",
        links: [
            {name: "Публичная оферта", href: "/oferta"},
            {name: "Политика конфиденциальности", href: "/policy"}
        ],
    }
];

const social = [
    {href: telegramIcon, alt: "tg", link: 'https://t.me/jvmbrain'},
    {href: youtubeIcon, alt: "yb", link: 'https://www.youtube.com/@jvmbrain'}
]
export default function Footer() {
    return (
        <footer
            className="text-center text-surface/75 dark:text-white/75 lg:text-left border-t-2 border-gray-500">
            <div
                className="flex items-center justify-center border-b-2 border-neutral-200 p-6 dark:border-white/10 lg:justify-between">
                <div className="flex flex-shrink-0 items-center">
                    <Link to="/">
                        <img
                            className="h-12 w-auto"
                            src={logo}
                            alt="JVM Brain"
                        />
                    </Link>
                    <b className="p-2 text-lg text-[#00abf1]">JVM Brain</b>
                </div>
                <div className="flex justify-center">
                    {social.map((item) => (
                        <Link to={item.link}>
                            <img
                                src={item.href}
                                alt={item.alt}
                                className="w-12 h-12 hover:bg-gray-400 text-white rounded-md mr-2 text-lg font-bold bg-[#00abf1]"
                            />
                        </Link>
                    ))}
                </div>
            </div>

            <div className="mx-6 py-10 text-center md:text-left">
                <div className="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4">
                    {sections.map((item) => (
                        <div>
                            <h6
                                className="mb-4 text-[#00abf1] flex justify-center font-semibold uppercase md:justify-start">
                                {item.title}
                            </h6>
                            {item.links.map((link) => (
                                <p className="mb-4 text-gray-300">
                                    <Link to={link.href}>{link.name}</Link>
                                </p>
                            ))}
                        </div>
                    ))}
                    <div>
                        <h6
                            className="mb-4 text-[#00abf1] flex justify-center font-semibold uppercase md:justify-start">
                            Контакты
                        </h6>
                        <p className="mb-4 flex items-center text-gray-300 justify-center md:justify-start">
                          <span className="me-3 [&>svg]:h-5 [&>svg]:w-5">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                fill="currentColor">
                              <path
                                  d="M1.5 8.67v8.58a3 3 0 003 3h15a3 3 0 003-3V8.67l-8.928 5.493a3 3 0 01-3.144 0L1.5 8.67z"/>
                              <path
                                  d="M22.5 6.908V6.75a3 3 0 00-3-3h-15a3 3 0 00-3 3v.158l9.714 5.978a1.5 1.5 0 001.572 0L22.5 6.908z"/>
                            </svg>
                          </span>
                            support@jvmbrain.ru
                        </p>
                        <p className="flex items-center justify-center md:justify-start text-gray-300">
                            Все замечания, вопросы и предложения направляйте по указанному адресу
                        </p>
                    </div>
                </div>
            </div>
            <div className="mx-auto items-center w-full h-50 justify-center text-white">
                <div className="flex mx-auto items-center justify-center">
                    <p className="p-4">JVMBrain © 2022-{new Date().getFullYear()} Все права защищены</p>
                </div>
                <div className="flex mx-auto items-center justify-center">
                    <p className="p-4">Не является публичной офертой</p>
                </div>
            </div>
        </footer>
    )
}