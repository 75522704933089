import {animated, useSpring} from "react-spring";
import {Link} from "react-router-dom";

const social = [
    {name: 'Telegram', count: 309, color: 'text-blue-500', link: 'https://t.me/jvmbrain'},
    {name: 'Youtube', count: 445, color: 'text-red-700', link: 'https://www.youtube.com/@jvmbrain'},
]

function classNames(...classes) {
    return classes.join(' ')
}

function Counter({end}) {
    const {number} = useSpring({
        from: {number: 0},
        number: end,
        delay: 500,
        config: {mass: 1, tension: 13, friction: 13}
    })
    return <animated.div>{number.to((end) => end.toFixed(0))}</animated.div>
}

export default function Social() {
    return (
        <div className="mx-auto relative items-center w-full justify-center text-white">
            <div className="flex items-center justify-center p-6">
                <h2 className="text-4xl mx-auto md:text-6xl font-semibold text-[#00abf1]">Нас читают и смотрят</h2>
            </div>
            <div className="flex items-center justify-center">
                {social.map((item) => (
                    <div className="inline-flex flex-col items-center justify-center mx-auto w-full md:w-1/2">
                        <div className="p-4">
                            <Link to={item.link}><h2 className="font-bold text-3xl md:text-5xl">{item.name}</h2></Link>
                        </div>
                        <div className={classNames('p-4 text-4xl md:text-9xl', item.color)}>
                            <p><Counter end={item.count}/></p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}