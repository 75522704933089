export default function About() {
    return (
        <div className="mx-auto relative items-center p-10 w-full h-30 justify-center bg-gray-900 text-white">
            <h2 className="text-3xl md:text-4xl font-semibold text-[#00abf1]">Наша цель</h2>
            <div className="pt-4">
                <p className="sm:w-full md:w-4/5 text-xl">
                    На рынке сейчас уже не востребованы просто программисты и даже разработчики, теперь предпочтение
                    отдается инженерам, и мы постараемся показать путь как им стать через теорию и практику разработки.
                </p>
            </div>
            <div className="pt-4">
                <p className="sm:w-full md:w-4/5 text-xl">
                    Будучи действующими разработчиками мы не только обучаем, но и создаем собственные продукты, для
                    которых в том числе есть планы по привлечению лучших выпускников курсов, ведь окончанием обучения
                    является не просто сданный итоговый проект, а полученное предложение о работе (он же оффер).
                </p>
            </div>
        </div>
    )
}