import './App.css';
import Navbar from "./components/Navbar";
import Social from "./components/Social";
import Footer from "./components/footer/Footer";
import Banner from "./components/Banner";
import Advantages from "./components/Advantages";
import VideoRecommendations from "./components/VideoRecommendations";
import About from "./components/About";

export default function App() {
    return (
        <div className="bg-gray-800">
            <Navbar/>
            <Banner/>
            <Social/>
            <Advantages/>
            <VideoRecommendations/>
            <About/>
            <Footer/>
        </div>
    )
}
