import React, {Fragment, useState} from "react";
import {Dialog, Transition} from "@headlessui/react";

export default function MailForm() {

    let [isOpenSuccess, setIsOpenSuccess] = useState(false)
    let [isOpenError, setIsOpenError] = useState(false)

    function closeSuccessModal() {
        setIsOpenSuccess(false)
    }

    function openSuccessModal() {
        setIsOpenSuccess(true)
    }

    function closeErrorModal() {
        setIsOpenError(false)
    }

    function openErrorModal() {
        setIsOpenError(true)
    }

    const onSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(event.target);

        formData.append("subject", "Заявка на курс Веб-сервисы на Java")

        const object = Object.fromEntries(formData);
        const json = JSON.stringify(object);

        try {
            const res = await fetch("http://jvmbrain.ru/api/mail/send", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                body: json
            }).then((res) => res.status);

            if (res === 200) {
                event.target.reset();
                console.log("Success", res)
                openSuccessModal()
            } else {
                openErrorModal()
            }
        } catch (e) {
            console.log(e)
            openErrorModal()
        }
    }

    return (
        <div className="py-2 sm:px-5 md:px-20 mx-auto">
            <form onSubmit={onSubmit} id="proporsal-form">
                <div className="grid sm:grid-cols-1 md:grid-cols-4 mx-auto">
                    <div className="w-full p-2 mx-auto">
                        <input type="text"
                               name="name"
                               className="shadow-sm bg-gray-50 border
                                          border-gray-300 text-gray-900
                                          text-sm rounded-lg block w-full p-2.5"
                               placeholder="Введите имя"
                               required/>
                    </div>
                    <div className="w-full p-2 mx-auto">
                        <input type="email"
                               name="email"
                               className="shadow-sm bg-gray-50 border
                                          border-gray-300 text-gray-900
                                          text-sm rounded-lg block w-full p-2.5"
                               placeholder="example@jvmbrain.ru"
                               required/>
                    </div>
                    <div className="w-full p-2 mx-auto">
                        <input type="text"
                               name="telegramNickName"
                               className="shadow-sm bg-gray-50 border
                                          border-gray-300 text-gray-900
                                          text-sm rounded-lg block w-full p-2.5"
                               placeholder="Логин telegram"
                               required/>
                    </div>
                    <div className="w-full p-2 mx-auto items-center">
                        <button type="submit"
                                className="text-gray-900 bg-white h-full w-full border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-bold rounded-lg text-xl px-5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">
                            Отправить заявку
                        </button>
                    </div>
                </div>

                <Transition appear show={isOpenSuccess} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeSuccessModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black/25"/>
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Заявка отправлена
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Ожидайте обработки вашей заявки! Благодарим за выбор нашего курса!
                                            </p>
                                        </div>

                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={closeSuccessModal}
                                            >
                                                Закрыть!
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>

                <Transition appear show={isOpenError} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeErrorModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black/25"/>
                        </Transition.Child>

                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel
                                        className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <Dialog.Title
                                            as="h3"
                                            className="text-lg font-medium leading-6 text-gray-900"
                                        >
                                            Ошибка отправки
                                        </Dialog.Title>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-500">
                                                Что-то пошло не так! При повторной попытке просьба свзаться с нами по
                                                любым удобным каналам связи
                                            </p>
                                        </div>

                                        <div className="mt-4">
                                            <button
                                                type="button"
                                                className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                                                onClick={closeErrorModal}
                                            >
                                                Закрыть!
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </form>
        </div>
    )
}

