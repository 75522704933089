export default function Advantages() {
    return (
        <div className="mx-auto relative items-center p-10 w-full h-30 justify-center bg-gray-900 text-white">
            <h2 className="text-3xl md:text-4xl font-semibold text-[#00abf1]">Почему выбирают нас</h2>
            <div className="pt-4">
                <p className="sm:w-full md:w-4/5 text-xl">
                    Обучение построено на основе живых онлайн-занятий в формате предварительного объяснения материала
                    с дальнейшим показом применения на практике. Мы всегда в курсе изменений востребованных технологий
                    по
                    своему направлению - Java и Kotlin. Мы не стремимся обучать по всем специальностям - только то, что
                    хорошо знаем и умеем сами, преподаватели и наставники - действующие разработчики со стажем. Материалы для
                    обучения корректируются на основе обратной связи и требований к современным разработчикам на рынке труда.
                </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mt-8">
                <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Живые уроки</h5>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Прямо во время занятия вы можете задать вопрос и почти всегда получить на него ответ.</p>
                </div>
                <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Обратная связь</h5>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Проверка домашнего задания, приближенная к рабочим условиям (ревью кода и замечания).</p>
                </div>
                <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Помощь с резюме</h5>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Часть курсов содержат отдельные встречи по составлению и корректировке резюме.</p>
                </div>
                <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">Интервью</h5>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">После успешного прохождения курса вы можете пройти мок-интервью для выявления пробелов в знаниях.</p>
                </div>
            </div>
        </div>
    )
}