import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import JavaWebCourse from "./pages/courses/java/JavaWebCourse";
import Blog from "./pages/Blog";
import Vacancy from "./pages/Vacancy";
import ErrorPage from "./pages/ErrorPage";
import Courses from "./pages/courses/Courses";
import Login from "./pages/login/Login";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>
    },
    {
        path: "/courses",
        element: <Courses/>,
    },
    {
        path: "/courses/java-web",
        element: <JavaWebCourse/>,
    },
    {
        path: "/blog",
        element: <Blog/>,
    },
    {
        path: "/vacancy",
        element: <Vacancy/>,
    },
    {
        path: "/login",
        element: <Login/>,
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
